var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-md-12 col-lg-12" },
        [
          _c(
            "c-table",
            {
              ref: "substanceTable",
              attrs: {
                editable: _vm.editable && !_vm.disabled,
                title: "구성성분 목록",
                tableId: "substanceTable",
                gridHeight: "350px",
                columns: _vm.grid.columns,
                data: _vm.review.subs,
                columnSetting: false,
                filtering: false,
                usePaging: false,
                hideBottom: true,
                selection: "multiple",
                rowKey: "mdmChemId",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: { label: "추가", icon: "add" },
                            on: { btnClicked: _vm.addrow },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: { label: "삭제", icon: "remove" },
                            on: { btnClicked: _vm.removeRow },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.saveSubUrl,
                              isSubmit: _vm.isSaveSub,
                              param: _vm.review.subs,
                              mappingType: "PUT",
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveSubstance,
                              btnCallback: _vm.saveSubstanceCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "innertable",
            staticClass: "topcolor-orange",
            attrs: {
              editable: false,
              tableId: "innertable",
              title: "구성성분별 규제사항(한국)",
              columns: _vm.grid2.columns,
              data: _vm.review.subsRegul,
              gridHeight: _vm.gridHeight,
              columnSetting: false,
              filtering: false,
              usePaging: false,
              hideBottom: true,
            },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }