<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-table
        ref="substanceTable"
        :editable="editable&&!disabled"
        title="구성성분 목록"
        tableId="substanceTable"
        gridHeight="350px"
        :columns="grid.columns"
        :data="review.subs"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :hideBottom="true"
        selection="multiple"
        rowKey="mdmChemId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <!-- <c-btn 
              v-if="editable&&!disabled" 
              label="환경부-화학물질정보 연동 추가" 
              icon="add" 
              @btnClicked="addApiRow" /> -->
            <c-btn 
              v-if="editable&&!disabled" 
              label="추가" 
              icon="add" 
              @btnClicked="addrow" />
            <c-btn 
              v-if="editable&&!disabled" 
              label="삭제" 
              icon="remove" 
              @btnClicked="removeRow" />
            <c-btn
              v-if="editable&&!disabled"
              :url="saveSubUrl"
              :isSubmit="isSaveSub"
              :param="review.subs"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveSubstance"
              @btnCallback="saveSubstanceCallback" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="innertable"
        :editable="false"
        tableId="innertable"
        title="구성성분별 규제사항(한국)"
        class="topcolor-orange"
        :columns="grid2.columns"
        :data="review.subsRegul"
        :gridHeight="gridHeight"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :hideBottom="true"
      >
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-review-components',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',
      }),
    },
    review: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',  // 사전검토 일련번호
        reviewRequestDate: '',  // 검토요청일
        plantCds: '',  // 사업장코드
        materialCd: '',  // 자재코드
        materialName: '',  // 자재명(KOR)
        materialNameEn: '',  // 자재명(ENG)
        deliveryVendorCd: null,  // 납품업체 코드
        deliveryVendorName: '',  // 납품업체명
        mfgVendorCd: null,  // 제조업체 코드
        mfgVendorName: '',  // 제조업체명
        mdmChemReviewStepCd: null,  // 사전검토 진행상태
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        hsNo: '',  // HS번호
        specificGravity: '',  // 비중
        casNo: '',  // CAS No.
        serialNo: '',  // 고유번호(유독물번호or고시번호)
        keNo: '',  // KE 번호
        psmFlag: 'N',  // PSM 대상 여부
        mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        returnRemark: '',  // 반려사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        subs: [],
        subsRegul: [],
        subsRegulSave: [],
        prodReguls: [],
        checks: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnEditable: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      // regulSave: {
      //   mdmChemReviewId: '',
      //   subsRegulSave: [],
      // },
      grid: {
        columns: [
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '화학물질명(ENG)',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'chemNmKr',
            field: 'chemNmKr',
            label: '화학물질명(KOR)',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            label: '함유량(%)',
            align: 'center',
            child: [
              {
                name: 'limitHigh',
                field: 'limitHigh',
                label: '상한',
                align: 'center',
                style: 'width:90px',
                type: 'number',
                sortable: false,
              },
              {
                name: 'limitLow',
                field: 'limitLow',
                label: '하한',
                align: 'center',
                style: 'width:90px',
                type: 'number',
                sortable: false,
              },
              {
                name: 'limitRepval',
                field: 'limitRepval',
                label: '대표값',
                align: 'center',
                style: 'width:80px',
                type: 'number',
                sortable: false,
              },
            ]
          },
        ],
      },
      grid2: {
        columns: [],
        fullcolumns: [],
      },
      editable: true,
      isSaveSub: false,
      headerUrl: '',
      saveSubUrl: '',
      deleteSubUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let _margin = 460;
      if (this.btnEditable) {
        _margin += 50;
      }
      return String(this.contentHeight - _margin) + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.headerUrl = selectConfig.mdm.mam.chem.header.url;
      this.saveSubUrl = transactionConfig.mdm.mam.review.substance.save.url;
      this.deleteSubUrl = transactionConfig.mdm.mam.review.substance.delete.url;
      // code setting
      // list setting
      this.getHeader();
    },  
    getHeader() {
      this.$http.url = this.headerUrl;
      this.$http.type = 'GET';
      this.$http.param = {headerType: 'check'};
      this.$http.request((_result) => {
        let _items = [{
          name: 'chemNmEn',
          field: 'chemNmEn',
          label: '화학물질명(ENG)',
          align: 'left',
          style: 'width:350px',
          sortable: false,
        }]
        let _fullcolumns = [];
        this.$_.forEach(_result.data, _item => {
          _items.push(_item);
          _fullcolumns = this.$_.concat(_fullcolumns, _item.child)
        })
        this.grid2.columns = this.$_.cloneDeep(_items);
        this.grid2.fullcolumns = _fullcolumns;
      },);
    },    
    addrow() {
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chem/chemBasePop.vue'}`);
      this.popupOptions.title = '화학물질';
      this.popupOptions.width = '60%';
      this.popupOptions.param = {
        type: 'multiple'
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChemBasePopup;
    },
    closeChemBasePopup(_data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if(_data && _data.length > 0) {
        this.$_.forEach(_data, item => {
          if (this.$_.findIndex(this.review.subs, { mdmChemId: item.mdmChemId }) === -1) {
            this.review.subs.splice(0, 0, {
              mdmChemReviewId: this.popupParam.mdmChemReviewId,
              mdmChemId: item.mdmChemId,
              casNo: item.casNo,
              chemNmKr: item.chemNmKr,
              chemNmEn: item.chemNmEn,
              limitHigh: 0,
              limitLow: 0,
              limitRepval: 0,
              mttrid: '',  // 환경부데이터 물질ID(CHEM ID)
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    removeRow() {
      let selectData = this.$refs['substanceTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteSubUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.review.subs = this.$_.reject(this.review.subs, item);
              })
              this.$refs['substanceTable'].$refs['compo-table'].clearSelection();
              this.$emit('getDetail');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveSubstance() {
      if (this.$comm.validTable(this.grid.columns, this.review.subs)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveSub = !this.isSaveSub
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveSubstanceCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail');
    },
  }
};
</script>

<style lang="sass">
.substanceTableCss .lanauageFlag
  margin: 0 -1.2em 0 -0.1em !important
.substanceTableCss .q-tab__label
  font-size: 0.80rem
  font-weight: 600
.substanceTableCss .q-tab
  height: 36px
.lawchemChip
  margin-right: 5px !important
  cursor: pointer
.lawchemChiptd
  line-height: 28px !important
.chemavatar
  width: 22px !important
  height: 22px !important
  cursor: pointer
</style>